import React from 'react';
import styled, { css } from 'styled-components';

import { NatalIcon } from 'src/assets/icons/maps/index';
import { SynastryIcon } from 'src/assets/icons/maps/index';
import { PrognosticsIcon } from 'src/assets/icons/maps/index';
import { HorarIcon } from 'src/assets/icons/maps/index';
import { SoulIcon } from 'src/assets/icons/maps/index';

import { PlusInCircleIcon } from 'src/assets/icons/system/index';
import { IFormData } from 'src/libs';
import { TMode } from '..';
import { useTranslation } from 'src/i18n/useTranslation';
import { isLimitedAccess, isBaseTariff, isTrialAccess } from 'src/store/localStorage/profile';

export const modes = {
  'natal': {
    icon: <NatalIcon />,
    name: "astro.natal"
  },
  'synastry': {
    icon: <SynastryIcon />,
    name: "astro.synastry"
  },
  'prognostics': {
    icon: <PrognosticsIcon />,
    name: "astro.prognostics"
  },
  'horar': {
    icon: <HorarIcon />,
    name: "astro.horar"
  },
  'soul': {
    icon: <SoulIcon />,
    name: "chronos.mobile.modeSelector.soulFormulas"
  },
}

export default function ModeSelector({
  mode,
  setMode,
  addMode,
  form,
  isLocked = false,
  isLockedNotify
}: {
  mode: string,
  setMode(mode: TMode):void,
  addMode(mode: TMode):void,
  form: IFormData;
  isLocked?: boolean;
  isLockedNotify(): void
}){
  const changeMode = (mode: TMode, isExist: boolean) => {
    if (isExist) {
      setMode(mode);
    } else if (!isLocked) {
      addMode(mode);
    } else {
      isLockedNotify?.();
    }
  }
  const { t } = useTranslation();

  const modesEntries = Object.entries(modes).filter(entry => {
    if (isLimitedAccess()) {
      return !['horar', 'soul', 'prognostics', 'synastry'].includes(entry[0])
    } else if (isTrialAccess() || isBaseTariff()){
      return !['horar', 'soul'].includes(entry[0])
    } else {
      return true
    }
  })

  const isExist = (mode: TMode) => {
    switch (mode) {
      case 'soul':
        return true;
      case 'synastry':
        return !!form.partners?.length;
      default:
        return !!form[mode];
    }
  }

  return <Container>
    {modesEntries.map((entry: any) => {
      const mode_: TMode = entry[0];
      const active = (mode_ === mode);
      const _isExist = isExist(mode_);

      if (entry[0] === 'natal' || !form.cosmogram) {
        return <Mode active={active} key={mode_} onClick={() => changeMode(mode_, _isExist)}>
          {entry[1].icon} {t(entry[1].name)}
          {!_isExist && <Plus><PlusInCircleIcon /></Plus>}
        </Mode>
      }
    })}
  </Container>
}

export const Container = styled.div`
  width: 100%;
  padding-top: 1rem;
  overflow-x: auto;
  white-space: nowrap;
`

export const Mode = styled.div<{active?: boolean}>`
  position: relative;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 6.5rem;
  height: 4.5rem;
  margin-left: 0.75rem;
  font-size: 0.75rem;
  border-radius: 0.5rem;

  & > svg {
    margin-bottom: 0.2rem;
  }

  ${p => p.active && css`
    background: var(--input-border);
    border: 1px solid var(--input-background);
    color: var(--text-primary);
  `}

  ${p => !p.active && css`
    background: var(--input-background);
    border: 1px solid var(--input-border);
    color: var(--text-secondary);
  `}

  & ::-webkit-scrollbar { /* chrome based */
    width: 0px;  /* ширина scrollbar'a */
    background: transparent;  /* опционально */
}
`

const Plus = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(50%, -50%);
`
