import React from 'react';
import styled from 'styled-components';

import { ObjectType, IPoint, getAspectsPairs, PairAspects, CircleMode, IAspect, getSign } from 'src/libs';

import { aspectsIcons, objectsIcons } from 'src/helpers/icons';

import { IWidgetData } from '../data';
import Icon from './Icon';
import OrbiseCorrector from '../OrbiseCorrector';
import { IHighlight } from 'src/app/pages/workspace';
import { isCompatibility, isPartner } from '../../../utils';
import { connect } from 'react-redux';

//import { IHighlight } from '~pages/Instruments';

const axes = [1, 2, 3, 5, 6, 10];
// const axesObjects = [ObjectType.House1, ObjectType.House2, ObjectType.House3, ObjectType.House5, ObjectType.House6, ObjectType.House10];

const axesPairs: {
  [key: number]: [number, number];
} = {
  [ObjectType.House1]: [1, 2],
  [ObjectType.House2]: [2, 2],
  [ObjectType.House3]: [3, 2],
  [ObjectType.House5]: [4, 2],
  [ObjectType.House6]: [5, 2],
  [ObjectType.House10]: [6, 2],

  [ObjectType.House7]: [1, 1],
  [ObjectType.House8]: [2, 1],
  [ObjectType.House9]: [3, 1],
  [ObjectType.House11]: [4, 1],
  [ObjectType.House12]: [5, 1],
  [ObjectType.House4]: [6, 1]
};

function headerIcon(i: number) {
  return (
    <Icon
      icon={i <= ObjectType.Chiron ? objectsIcons[i] : null}
      text={i <= ObjectType.Chiron ? '' : axes[i - ObjectType.Chiron - 1].toString()}
    />
  );
}

function cellClassName(i: number, dir: 'h' | 'v') {
  const diff = i - ObjectType.Chiron;
  // в switch кроме 11 и 12, были добавляны для автотестов 
  switch (i) {
    case 19: return `${dir}-axis House10`
    case 18: return `${dir}-axis House6`
    case 17: return `${dir}-axis House5`
    case 16: return `${dir}-axis House3`
    case 15: return `${dir}-axis House2`
    case 14: return `${dir}-axis House1`
    case 12: return 'lilith'
    case 11: return 'south-node'
    case 10: return 'NorthNode'
    case 9: return 'Pluto'
    case 8: return 'Neptune'
    case 7: return 'Uranus'
    case 6: return 'Saturn'
    case 5: return 'Jupiter'
    case 4: return 'Mars'
    case 3: return 'Venus'
    case 2: return 'Mercury'
    case 1: return 'Moon'
    case 0: return 'Sun'
    default: return diff < 0 ? '' : diff ? `${dir}-axis` : 'chiron';
  }
}

function getCellIndex(i: ObjectType) {
  const gap = (ObjectType.House1 - ObjectType.Chiron) - 1;

  if (i <= ObjectType.Chiron) {
    return {
      id: i + 1,
      w: 1
    };
  } else if (i >= ObjectType.House1) {
    const p = axesPairs[i];

    return {
      id: p[0] + ObjectType.House1 - gap,
      w: p[1]
    };
  }

  // const p = axesPairs[i];
  //
  // return {
  //   id: p[0] + ObjectType.House1,
  //   w: p[1]
  // };
}

interface IAspectsTableCell {
  cell: React.RefObject<HTMLTableDataCellElement>;
  icon: React.RefObject<Icon>;
  aspectId: number;
  weight: number;
}

interface AspectsTableProps {
  data: IWidgetData;
  onChanged(key: string, value: any): void;
  openedWidget: boolean;
  activeProfileId: number;
}

export function highlightAspect(map: CircleMode, aspect: IAspect, hasExt: boolean): IHighlight[] {
  if (!aspect) { return [] }

  const type = (obj: ObjectType, isExt = false) => ({
    id: obj >= ObjectType.House1 ? obj - ObjectType.House1 : obj,
    type: `${obj >= ObjectType.House1 ? 'house' : 'object'}${isExt ? '_ext' : ''}`
  });

  return [
    {
      id: aspect.id,
      type: 'aspect',
      map
    },
    {
      ...type(aspect.obj1),
      map
    },
    {
      ...type(aspect.obj2, hasExt),
      map
    },
    {
      type: 'sign',
      id: getSign(aspect.lon1),
      map
    },
    {
      type: 'sign',
      id: getSign(aspect.lon2),
      map
    }
  ] as IHighlight[];
}

export function hasExt(mode: CircleMode) {
  if(isCompatibility(mode) || isPartner(mode)) {
    return true;
  }
  return ['transits', 'directions', 'solars', 'prog_natal'].includes(mode);
}

class AspectsTable extends React.Component<AspectsTableProps> {
  private readonly _ref = React.createRef<HTMLTableElement>();
  private readonly _cells: IAspectsTableCell[][] = [];
  private _prevHover: IPoint | null = null;

  constructor(props: AspectsTableProps) {
    super(props);

    for (let i = 0; i < 21; i++) {
      const row: IAspectsTableCell[] = [];

      for (let j = 0; j < 21; j++) {
        row.push({
          cell: React.createRef<HTMLTableDataCellElement>(),
          icon: React.createRef<Icon>(),
          weight: 0,
          aspectId: -1
        });
      }

      this._cells.push(row);
    }
  }

  async componentDidMount() {
    this.update(this.props);
  }

  componentWillUnmount() {
    this.props.onChanged('pinned-aspects', []);
  }

  shouldComponentUpdate(newProps: AspectsTableProps, newState: any) {
    if (newProps.data.form.cosmogram !== this.props.data.form.cosmogram) return true;
    this.update(newProps);
    return true; //return false;
  }

  updateCells(props: AspectsTableProps, show: boolean, mode: CircleMode) {
    const map = props.data.maps.find(m => m.mode === props.data.aspectsTableMode);

    if (!map) { return }

    const aspects = map.aspects.filter(a => a.obj1 !== ObjectType.Selena && a.obj2 !== ObjectType.Selena)

    const pairAspects: PairAspects = {};

    getAspectsPairs(aspects, 'map', pairAspects);

    const asps = new Set<number>();

    /*const highlights = show ? props
      .data
      .highlights
      .filter(h => h.map == props.data.aspectsTableMode && h.type == 'aspect')
      .map(h => h.id) : [];

    if (show && this._prevHover && highlights.length == 0) {
      const { x, y } = this._prevHover;
      this.highlight(y, x);
    }*/

    aspects.forEach(asp => {
      const col = getCellIndex(asp.obj1);
      const row = getCellIndex(asp.obj2);
      const w = row!.w + col!.w;

      let cell

      if (mode === 'natal' || mode === 'syn_natal' || mode.startsWith('partner')) {
        
        if (asp.obj2 >= ObjectType.House1) {
          cell = this._cells[row!.id][col!.id]

        } else {
          cell = this._cells[col!.id][row!.id]
        }
      } else {
        cell = this._cells[col!.id][row!.id];
      }


      // const isDisabled = astro.getOrbise(map.mode, asp.obj1, asp.type)?.disabled;

      if (!cell || (show && w < cell.weight)) { return }

      (cell.icon?.current)?.setIcon(show ? aspectsIcons[asp.type] : null, `var(--circle-aspects-${asp.type})`);

      cell.aspectId = show ? asp.id : -1;
      cell.weight = show ? w : 0;

      asps.add(asp.id);

      /*if (highlights.includes(asp.id)) {
        this.highlight(row.id, col.id);
      }*/

      if (props.data.pinnedAspects.includes(asp.id)) {
        cell.cell.current!.classList.toggle('pinned');
      }
    });

  }

  clearCells(props: AspectsTableProps) {
    for (let i = 0; i < 21; i++) {
      for (let j = 0; j < 21; j++) {
        const cell: IAspectsTableCell = this._cells[i][j];
        cell.icon?.current?.setIcon(null, '');
        cell.aspectId = -1;
      }
    }
  }

  update(props: AspectsTableProps) {
    // console.log(`AspectTable update aspects - `, props.data.maps[0].aspects)

    const mode = props.data.aspectsTableMode;
    const isPrognostics = ['directions', 'solars', 'prog_natal', 'transits'].includes(mode);

    const ref = this._ref.current as HTMLTableElement;

    ref.setAttribute('data-small', isPrognostics.toString());

    ref.setAttribute('data-show-chiron', (mode === 'transits').toString());

    ref.setAttribute('data-show-h-axis', (mode === 'transits' || isPrognostics || ['natal','syn_natal'].includes(mode) || mode.startsWith('relocation') || mode.startsWith('partner')).toString());
    ref.setAttribute('data-show-v-axis', isPrognostics.toString());

    if (!(mode === 'transits' || isPrognostics)) {
      ref.querySelectorAll('tr td:not(.chiron):not(.v-axis)').forEach((item, i) => {
        if ((i + 1) % 14 === 0) { item.classList.add('withoutRightBorder') }
      });
    } else {
      ref.querySelectorAll('tr td:not(.chiron):not(.v-axis)').forEach((item, i) => {
        if ((i + 1) % 14 === 0) { item.classList.remove('withoutRightBorder') }
      });
    }

    this.updateCells(this.props, false, mode);

    this.clearCells(props);

    this.updateCells(props, true, mode);

    this.hideAspect(ref, mode)

    /* if (this.props.data.pinnedAspects.length > 0) {
      this.props.onChanged("pinned-aspects", []);
    }*/
  }

  /*highlight(row: number, col: number) {
    const cell = this._cells[row][col];

    const fill = (row: number, col: number) => {
      for (let i = 0; i < col; i++) {
        this._cells[row][i].cell.current!.classList.toggle('h-line');
        if (this._cells[row - 1]?.[i]?.cell?.current) { this._cells[row - 1][i].cell.current!.classList.toggle('h-line-prev') }
      }

      for (let i = 0; i < row; i++) {
        if (this._cells[i]?.[col - 1]?.cell?.current) { this._cells[i][col - 1].cell.current!.classList.toggle('v-line-prev') }
        this._cells[i][col].cell.current!.classList.toggle('v-line');
      }

      this._cells[row][0].cell.current!.classList.toggle('h-first');
      this._cells[0][col].cell.current!.classList.toggle('v-first');

      this._cells[row][col].cell.current!.classList.toggle('current');
    };

    if (this._prevHover) {
      fill(this._prevHover.y, this._prevHover.x);
    }

    if (cell.weight) {
      this._prevHover = {
        x: col,
        y: row
      };

      fill(row, col);
    } else {
      this._prevHover = null;
    }
  }*/

  /*onHover(row: number, col: number) {
    // if (this._prevHover && this._prevHover.x == col && this._prevHover.y == row) return;

    const cell = this._cells[row][col];

    if (!this._prevHover
      && cell.aspectId == -1
      && row !== 0
      && col !== 0) { return }

    const map = this.props.data.aspectsTableMode;

    this.props.onChanged(
      'highlights',
      cell.aspectId == -1 ?
        [] :
        highlightAspect(
          map,
          this.props.data.maps.find(m => m.mode == map)!.aspects[cell.aspectId],
          hasExt(map)
        )
    );

    this.highlight(row, col);
  }*/

  onClick(ev: any, y: number, x: number) {

    const { aspectId } = this._cells[y][x];

    let pinned: number[] = [];

    if (aspectId !== -1) {
      if (ev.ctrlKey || ev.metaKey) {
        if (this.props.data.pinnedAspects.includes(aspectId)) {
          pinned = this.props.data.pinnedAspects.filter(id => id !== aspectId);
        } else {
          pinned = [...this.props.data.pinnedAspects, aspectId];
        }
      } else {
        pinned = [aspectId];
      }
    }

    this.props.onChanged('pinned-aspects', pinned);
  }

  hideAspect(ref: HTMLTableElement, mode: CircleMode) {
    const rowLilith = ref.querySelector('td.lilith.row_header')?.parentElement
    const rowChiron = ref.querySelector('td.chiron.row_header')?.parentElement
    const rowSouthNode = ref.querySelector('td.south-node.row_header')?.parentElement

    if (mode === 'transits' && this.props.activeProfileId === 0) {
      rowLilith?.classList.add('not-show')
      rowChiron?.classList.add('not-show')
      // rowSouthNode?.classList.add('not-show')
    } else {
      rowLilith?.classList.remove('not-show')
      rowChiron?.classList.remove('not-show')
      rowSouthNode?.classList.remove('not-show')
    }
  }

  render() {
    const rows = [];

    const headers: any[] = [<Cell key="corner" className="v-header" ref={this._cells[0][0].cell}><Icon dot /></Cell>];

    const isTransits = this.props.data.aspectsTableMode === 'transits'
    const isActiveProfilePA = this.props.activeProfileId === 0; // Школа ПА
    const isCompatibility = this.props.data.aspectsTableMode.startsWith('compatibility') // добавленна для автотестов в data-td-display
    const hidedModes = ['natal','partner','relocation_natal','compatibility'].some(mode => this.props.data.aspectsTableMode.startsWith(mode)); // добавленна для автотестов в data-td-display
    const prognosticModes = ['solars','directions','prog_natal'].some(mode => this.props.data.aspectsTableMode.startsWith(mode)) // добавленна для автотестов в data-td-display
 

    for (let i = 0; i < 20; i++) {
      headers.push(
        <Cell
          key={`col_header_${i}`}
          className={`v-header ${cellClassName(i, 'v')}`}
          ref={this._cells[0][i + 1].cell}
        >
          {headerIcon(i)}
        </Cell>
      );
    }
    rows.push(<Row key={'col_headers'}>{headers}</Row>);
    const cosmogramNatalmode = this.props.data.form?.cosmogram && this.props.data.modes.includes('natal')
    const quantityOfCells = (this.props.data.form?.cosmogram && this.props.data.modes.includes('transits')) || cosmogramNatalmode ? 14 : 20;
    
    for (let i = 0; i < quantityOfCells; i++) {
      const hClassName = cellClassName(i, 'h');

      const chironOrLilith = i === ObjectType.Chiron  || i === ObjectType.Lilith
      const hideRowMark = ( // добавленна для автотестов в data-tr-display
        (!isTransits && i === ObjectType.Chiron) 
        || (isTransits && isActiveProfilePA && chironOrLilith)
        || (isCompatibility && i >= ObjectType.Chiron)
      )

      const cols: any[] = [
        <Cell
          key={`row_header_${i}`}
          ref={this._cells[i + 1][0].cell}
          className={`${hClassName} row_header`}
        >
          {headerIcon(i)}
        </Cell>
      ];

      for (let j = 0; j < quantityOfCells; j++) {
        cols.push(
          <Cell
            key={`cell_${j}`}
            //onMouseEnter={() => this.onHover(i + 1, j + 1)}
            //onMouseLeave={() => this.onHover(0, 0)}
            onClick={ev => this.onClick(ev, i + 1, j + 1)}
            ref={this._cells[i + 1][j + 1].cell}
            className={`${cellClassName(j, 'v')} ${hClassName}`}
            data-td-display={`${(hidedModes && j >= ObjectType.Chiron) || (prognosticModes && j === ObjectType.Chiron)  ? 'none' : ''}`} 
          >
            <Icon ref={this._cells[i + 1][j + 1].icon} />
          </Cell>
        );
      }
      rows.push(<Row key={`row_${i}`}  data-tr-display={`${hideRowMark ? 'none' : ''}`}>{cols}</Row>);
    }

    return (
      <Container>
        <TableWrapper>
          <Table ref={this._ref}>
            <tbody>
              {rows}
            </tbody>
          </Table>
        </TableWrapper>
        <OrbiseCorrector {...this.props} />
      </Container>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    activeProfileId: state.settings.settings.data.activeProfileId
  };
};

export default connect(
  mapStateToProps,

)(AspectsTable);

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

const TableWrapper = styled.div`
  width: 100%;
  overflow-x: scroll;
  margin-bottom: 0.875rem;
`

const Table = styled.table`
  border-collapse: collapse;

  box-sizing: border-box;

  &[data-small=true] {
    td {
      width: 1.5em;
      height: 1.5em;
    }
  }

  &[data-show-h-axis=false] {
    td.h-axis {
      display: none;
    }
  }

  &[data-show-v-axis=false] {
    td.v-axis {
      display: none;
    }
  }

  &[data-show-chiron=false] {
    td.chiron {
      display: none;
    }
  }

  .not-show {
    display: none;
  }
`;

const Row = styled.tr`
  :first-child td{
    border-top: 1px solid var(--aspects-table-border);
    border-bottom: 1px solid var(--aspects-table-border-light);
  }

  & > td:first-child{
    border-right: 1px solid var(--aspects-table-border-light);
  }
`;

const Cell = styled.td`
  padding: 0;
  box-sizing: border-box;

  border-right: 1px solid var(--aspects-table-border);
  border-bottom: 1px solid var(--aspects-table-border);


  &.pinned {
    background: var(--aspects-table-background-hover);
    position: relative;

    &:before{
      display: block;
      content: '';
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      transform: translate(-1px, -1px);
      border: 1px solid var(--aspects-table-border-light);
      border-radius: 1px;
    }
  }


  &.current {
    background: var(--aspects-table-background-current);
    position: relative;

    &:before{
      display: block;
      content: '';
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      transform: translate(-2px, -2px);
      border: 2px solid #EF5350;
      border-radius: 4px;
    }
    /* border: 1px solid #EF5350; */
  }

  &.h-line {
    background: var(--aspects-table-background-hover);
    border-bottom-color: var(--aspects-table-border-light);
  }
  &.h-line-prev {
    border-bottom-color: var(--aspects-table-border-light);
  }
  &.h-first {
    position: relative;
    &:after{
      display: block;
      content: '';
      top: 0;
      position: absolute;
      width: 1px;
      height: 100%;
      background-color: var(--aspects-table-border-light);
    }
  }

  &.v-line {
    background: var(--aspects-table-background-hover);
    border-right-color: var(--aspects-table-border-light);
  }
  &.v-line-prev {
    border-right-color: var(--aspects-table-border-light);
  }
  &.v-first {
    position: relative;
    &:after{
      display: block;
      content: '';
      top: 0;
      position: absolute;
      width: 100%;
      height: 1px;
      background-color: var(--aspects-table-border-light);
    }
  }

  &.withoutRightBorder {
    border-right: none;
  }
`;
