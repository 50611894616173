import { useTranslation } from 'src/i18n/useTranslation';
import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import SwipeScreens, { ISwipeTab } from 'src/ui/SwipeScreens';
import { IWidget, IWidgetData } from './data';
import { CircleMode } from 'src/libs';

export default function Widgets({
  widgets,
  widgetsData,
  active,
  currentMode,
  onWidgetChange,
	onOpenWidget
}:{
  widgets: IWidget[],
  widgetsData: IWidgetData,
  active?: string,
  currentMode: CircleMode,
  onWidgetChange(type: string, data: any): void,
	onOpenWidget(id: string): void
}) {
  const { t } = useTranslation();
  const [openedWidget, setOpenedWidget] = useState<number>(0)

	useEffect(() => {
		active && onOpenWidget(items[openedWidget].key!)
	}, [openedWidget])

  const items: ISwipeTab[] = widgets.map((w, index) => {
    const WidgetComponent = w.component;
    
    return {
      key: w.id,
      icon: w.icon,
      title: w.full,
      content: WidgetComponent 
        ? <WidgetComponent 
            data={widgetsData}
            onChanged={onWidgetChange}
            openedWidget={index === openedWidget} 
            /> 
        : <>'no-comp'</>,
      fullscreen: w.fullscreen,
    }
  });


  return <SwipeScreens
    items={items}
    active={active || widgets[0]?.id}
    mode={currentMode}
    setOpenedWidget={setOpenedWidget}
  />
}

export const WidgetHeader = styled.div`
  height: 1.5rem;
  margin-bottom: 0.5rem;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  font-size: 1.125rem;

  > svg:first-child {
    margin-right: 0.5rem;
  }

  > span {
    padding-top: 0.125rem;
  }
`
