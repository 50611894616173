import { profileMode, RangeInput } from 'src/libs';
import React, { useEffect, useState, useCallback } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { IWidgetData } from '../data';
import { debounce } from 'lodash'
import { useTranslation } from 'src/i18n/useTranslation';

export default React.memo(function OrbiseCorrector({
  data,
  onChanged,
  openedWidget,
}: {
  data: IWidgetData,
  onChanged(key: string, value: any): void,
  openedWidget: boolean,
}){
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [value, setValue] = useState(0);

  const activeAstroProfile: any = data.activeAstroProfile;

  const normalizeMode = profileMode(data.aspectsTableMode);
  const aspectsTableMode = data.aspectsTableMode;

  useEffect(() => {
    if(aspectsTableMode === "natal") {
      const newValue = activeAstroProfile.maps[normalizeMode].orbiseCorrector || 0;
      setValue(newValue);
    } else {
      data.maps.map((map, idx) => {
      if(map.mode === aspectsTableMode) {
        const newValue = activeAstroProfile?.maps[idx]?.orbiseCorrector || 0;
        return setValue(newValue); 
      }
    }) 
  }
  }, [data.aspectsTableMode])


  
  const onChangeDebounced = useCallback(debounce((value) => {
    if(aspectsTableMode === "natal") {
      onChanged('orbise-corrector', {
        activeAstroProfileId: activeAstroProfile.id,
        normalizeMode,
        value
      })
    } else {
      data.maps.map((map, idx) => {
      if(map.mode === aspectsTableMode) {
        onChanged('orbise-corrector', {
          activeAstroProfileId: activeAstroProfile.id,
          aspectsTableMode,
          value
        })
      }
    }) 
  }
  }, 256), [data.aspectsTableMode]);

  const onChangeNew = (value: number) => {
    setValue(value);
    setTimeout(() => {
      onChangeDebounced(value);
    })
  }



  return <StyledRangeInput
    label={t("chronos.mobile.mapSettings.orbises")}
    value={value}
    onChange={onChangeNew}
    min={-100}
    max={100}
    step={1}
    height={'3rem'}
  />
});

const StyledRangeInput = styled(RangeInput)`

  & input[type="range"] {
    display: block;
    position: relative;
    width: calc(100% - 4px);
    margin: 0 auto;
    background-image: none;
    border-radius: 0.5rem;
    background-color: var(--bg-element-neutral);
    border: 1px solid var(--border-lines);
  }

  &::-webkit-slider-thumb {
    box-sizing: border-box;
    width: 2rem;
    border-radius: 0.5rem;
    background: none;
    background-color: var(--bg-element-neutral);
    /* border: 1px solid var(--border-lines); */
  }
  &::-moz-range-thumb {
    box-sizing: border-box;
    width: 2rem;
    border-radius: 0.5rem;
    background: none;
    background-color: var(--bg-element-neutral);
    /* border: 1px solid var(--border-lines); */
  }
`;
