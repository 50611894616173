import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';

//import { ShareIcon } from 'src/assets/icons/system/index';
import { ChronosIcon } from 'src/assets/icons/profiles/index';
import { EditIcon } from 'src/assets/icons/system/index';
import { Radio, CircleMode, housesPower, IMap, planetsPower, IComputedRadius, IFormData, ICustomizationData, ICustomization, makeForm } from 'src/libs';
import { defaultComputedRadius } from 'src/app/pages/workspace/components/Circle/settings/personalization';
import { calculation } from 'src/app/pages/workspace/utils';
import { useSelector } from 'src/store/utils';
import { allSettings, getActiveAstroProfile } from 'src/store/reducers/settings/selectors';
import astro from 'src/astro';
import Circle from 'src/app/pages/workspace/components/Circle';

const initialStrongs = {
  planets: [],
  houses: {
    houses: [],
    planets: [],
    power: []
  },
  aspects: []
};

export default function NewMap({
  active = false,
  onClick,
  data
}: {
  active?: boolean;
  onClick():void;
  data: ICustomization;
}) {
  const form = makeForm();

  const [maps, setMaps] = useState<IMap[]>([]);
  const [strongs, setStrongs] = useState<any>(null);
  const nop = () => { };
  const computedRadius = data.data.computedRadius || defaultComputedRadius;
  const { data: settings } = useSelector(allSettings);

  const { radius: { circle: radius } } = data.data.circle;

  const diameter = radius * 2;

  const mapsWidth = diameter;


  const activeAstroProfile = useSelector(getActiveAstroProfile);

  useEffect(() => {
    const getInitialData = async () => {
      let natal: IMap | null = null;

      const calculate = async (form: IFormData, modes: CircleMode[]) => {
        const maps = await Promise.all(modes.map(async mode => {
          form.natal.place.lat = 55.75222;
          form.natal.place.lon = 37.61556;
          const data = await calculation(mode, form, activeAstroProfile, settings.ui.instruments.cardSettingsForms.horar.showAspects);

          // FIXME:
          // @ts-ignore
          const map: IMap = {
            mode,
            ...data
          };

          if (mode == 'natal') {
            natal = map;
          }

          return map;
        }));
        setStrongs(natal ? {
          planets: planetsPower(natal),
          houses: housesPower(natal),
          aspects: astro.strongAspects(natal)
        } : initialStrongs);

        setMaps(maps);
      };

      calculate(form, ['natal', 'transits']);
    };
    getInitialData();
  }, []);


  return <Container active={active} onClick={onClick}>
    <span>
      <AvatarName>
        <ChronosIcon />
        {data.data.author}
      </AvatarName>
        {Boolean(maps.length) && <svg
          viewBox={`0 0 ${mapsWidth} ${diameter}`}
          width={'100%'}
          height={'250'}
          shapeRendering="geometricPrecision"
          overflow="visible"
        >
          <Circle
            mode={maps[0].mode}
            x={radius}
            y={radius}
            r={radius}
            houses={maps[0].houses}
            objects={maps[0].objects}
            housesExt={maps[0].housesExt || []}
            objectsExt={maps[0].objectsExt || []}
            fixedStars={maps[0].fixedStars || []}
            //@ts-ignore
            showFixedStars={maps[0].showFixedStars}
            aspects={maps[0].aspects}
            strongs={strongs}
            personalization={data.data as ICustomizationData}
            computedRadius={computedRadius as IComputedRadius}
            onChanged={() => {
            }}
            isEditor={true}

            chains={[]}
            soulObjects={[]}
            soulStrongs={[]}
          />
        </svg>}


      {/*<EditIcon_ />*/}
    </span>
    <NameBlock >
      <Name>
        {data.data.name}
        {data.data.isHot && <Sticker>HOT</Sticker>}
        {/*<Share>
          <ShareIcon />
          874
          <Peoples>
            <AvatarImage />
            <AvatarImage />
            <AvatarImage />
          </Peoples>
        </Share>*/}
      </Name>
      <Radio
        value={null}
        label=""
        checked={active}
        onChange={() => { }}
      />
    </NameBlock>
  </Container>
}

const Container = styled.div<{ active?: boolean }>`
  margin-top: 0.75rem;
  border: 1px solid var(--border-lines);
  border-radius: 5px;

  ${p => p.active && css`
    border: 2px solid var(--color-blue);
  `}
  > span {
    text-align: center;
    display: block;
    padding-top: 3rem;
    padding-bottom: 3rem;
    position: relative;

    > svg {
      width: 75%;
      display: inline-block;
      margin: 2rem 0 0;
    }
  }
`

const EditIcon_ = styled(EditIcon)`
  width: auto !important;
  color: var(--text-primary);
  opacity: 0.5;
  position: absolute;
  right: 1rem;
  top: 1.3125rem;
`

const AvatarName = styled.div`
  position: absolute;
  top: 1.3125rem;
  left: 1.125rem;
  font-size: 0.875rem;
  line-height: 1rem;

  > svg {
    width: 1.2rem;
    display: inline-block;
    margin-right: 0.625rem;
    vertical-align: middle;
  }
`

const NameBlock = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 0.875rem;
  line-height: 1rem;
  padding: 0.8125rem 0.75rem;
  background-color: var(--bg-dashboard-cell);
  text-align: left;
`

const Sticker = styled.div`
  display: inline-block;
  background-color: var(--color-orange);
  color: var(--text-primary);
  border-radius: 3px;
  padding: 0.2rem 0.3125rem 0.25rem;
  font-size: 0.625rem;
  line-height: 0.625rem;
  margin-left: 0.5rem;
`

const Name = styled.div`
  font-size: 1rem;
  line-height: 1.1875rem;
`

const Share = styled.div`
  margin-top: 0.5rem;
  color: var(--text-secondary);
  font-size: 0.875rem;
  line-height: 1rem;

  > svg {
    display: inline-block;
    vertical-align: middle;
    color: var(--text-third);
    margin-right: 0.375rem;
  }
`

const Peoples = styled.div`
  display: inline-block;
  vertical-align: middle;
  padding-left: 0.4rem;

  > svg:first-child {
    transform: none;
  }
  > svg {
    display: inline-block;
    border: 0.2rem solid #292929; //ЦВЕТ НАДО ЗАМЕНИТЬ
    transform: translateX(-0.5rem);
    border-radius: 50%;
    box-sizing: initial;
  }
  > svg:last-child {
    transform: translateX(-1rem);
  }
`