import { IBaseData, IFormData, CircleMode, ICalculation, ObjectType, directionShift, getDirections, getLocalTime, isPrognostics, isSynastry, localTime, ISynastryPartnerData, IAspect, AspectsPatterns, IAspectPattern } from 'src/libs';
import { cloneDeep } from "lodash";
import React from "react";
import {
	CompatibilityIcon,
	DirectionsIcon,
	HorarIcon,
	NatalIcon,
	Partner1Icon,
	Partner2Icon,
	Partner3Icon,
	Partner4Icon,
	Partner5Icon,
	Partner6Icon,
	Partner7Icon,
	Partner8Icon,
	Partner9Icon,
	PartnerIcon,
	ProgNatalIcon,
	ProgProgIcon,
	SolarsIcon,
	SoulIcon,
	TransitsIcon
} from 'src/assets/icons/maps';
import astro from "src/astro";
import { Dispositors } from 'src/helpers/Dispositors';

export enum StatusType {
  ruler,
  exaltation,
  triplicity,
  bounds,
  face,
  fall,
  detriment
}

function calcNatalAspectsWithFloatingOrbiseCorrector(aspects: IAspect[], natal: ICalculation, floatingOrbise: number): IAspect[] {
  if (!floatingOrbise) return aspects;

  let newAspects = astro.natalAspects(natal.objects, undefined, natal.houses, undefined, { floatingOrbise });
  const newConfigurations = new AspectsPatterns(newAspects.filter(item => item.obj1 < 10 && item.obj2 < 10)).findPatterns() as IAspectPattern[];
  const isSameAspects = (aspect1: IAspect, aspect2: IAspect) => (aspect1.obj1 === aspect2.obj1 && aspect1.obj2 === aspect2.obj2) || (aspect1.obj1 === aspect2.obj2 && aspect1.obj2 === aspect2.obj1);
  const aspectInConfigurations = ((aspect: IAspect, configurations: IAspectPattern[]) => configurations.find((configuration: IAspectPattern) => configuration.aspects.find((confAspect: IAspect) => isSameAspects(confAspect, aspect))));

  const aspectInArrayOfAspects = ((aspect: IAspect, aspects: IAspect[]) => aspects.find((confAspect: IAspect) => isSameAspects(confAspect, aspect)));

  newAspects = newAspects.map(a => {
    let aspect = a;
    // Если аспект не входит в конфигурации, но при этом уже был, то снимаем с него орбисы и всё всё всё, дабы он был прежним
    if (aspectInArrayOfAspects(a, aspects) && !aspectInConfigurations(a, newConfigurations)) {
      aspect = aspects.find((aspect: IAspect) => isSameAspects(a, aspect)) as IAspect;
    }

    return aspect;
  });
  aspects = newAspects.filter(aspect => aspectInConfigurations(aspect, newConfigurations) || aspectInArrayOfAspects(aspect, aspects));
  // Ставим индексы и id на место
  aspects = aspects.map((item, i) => {
    item.id = i;
    return item;
  });

  return aspects;
}

export async function calcSoul(dt: string, gmt: number | null, lat: number, lon: number) {
  const data = await astro.natal(getLocalTime(dt, gmt, lat, lon), lat, lon);

  const disp = Dispositors(data.objects);

  return {
    ...data,
    ...disp
  };
}

export async function calculation(mode: CircleMode, form: IFormData, activeAstroProfile: any, showAspects?: any) {

  const profile = activeAstroProfile;
  const floatingOrbise = (activeAstroProfile && activeAstroProfile.closureConfig) ?? 0

  if (profile) {
    astro.settings = {
      ...cloneDeep(profile),
      housesSystem: form.housesSystem || {common: 0, northern: 3, horar: 2}, // TODO:
    };

    const maps = astro.settings.maps;

    if (maps) {
      Object.keys(maps).forEach(k => {
        (astro.settings.maps as any)[k].orbiseCorrector = (maps as any)[k].orbiseCorrector;
      });
    }
  }

  const ErisObjectNatal = await astro.object(localTime(form.natal), 136199 + 10000);

  const natal: ICalculation = ['horar', 'soul'].includes(mode)
    ? {
        houses: [],
        objects: [],
      }
    : await astro.natal(
        localTime(form.natal),
        form.natal.place.lat,
        form.natal.place.lon,
        form.cosmogram,
      );

  if (mode === 'natal' || mode === 'syn_natal') {

    let aspects = astro.natalAspects(natal.objects, undefined, natal.houses, undefined, { floatingOrbise: 0 });
    aspects = calcNatalAspectsWithFloatingOrbiseCorrector(aspects, natal, floatingOrbise);

    return {
      ...natal,
      objects: [...natal.objects, ErisObjectNatal],
      aspects,
      objectsExt: [],
      housesExt: [],
    };
  }

  if (mode === 'horar') {
    if (!form.horar) {
      return null;
      throw new Error('Empty horar data')
    };


    const horar = await astro.horar(
      localTime(form.horar),
      form.horar.place.lat,
      form.horar.place.lon,
    );

    const ErisObjectHorar = await astro.object(localTime(form.horar), 136199 + 10000);

    return {
      ...horar,
      objects: [...horar.objects, ErisObjectHorar],
      aspects: showAspects ? astro.natalAspects(horar.objects) : [],
      objectsExt: [],
      housesExt: [],
    };
  }

  if (mode === 'soul') {
    const soul = await calcSoul(
      form.natal.dt,
      form.natal.gmt,
      form.natal.place.lat,
      form.natal.place.lon
    );

    return {
      ...soul,
      aspects: [],
      soulObjects: soul.objectsExt,
      housesExt: []
    };
  }

  if (isSynastry(mode)) {
    const data = getModeData(mode, form);

    if (!data) {
      return null;
    };

    const partners = form.partners?.length
    ? form.partners
    : form.synastry
        ? [form.synastry]
        : null;

    if (!partners) { throw new Error('Empty synastry data') };

    const partnerIndex = +mode.slice(-1) - 1 ?? 0;
    const partner = partners[partnerIndex];
    const ErisObjectSynastry = await astro.object(localTime(data), 136199 + 10000);
    const ErisObjectPartner = await astro.object(localTime(partner), 136199 + 10000);

    const ext = await astro.natal(
      localTime(data),
      data.place.lat,
      data.place.lon,
      false,
    );

    if(isCompatibility(mode)) {
      return {
        ...natal,
        objects: [...natal.objects, ErisObjectNatal],
        aspects: astro.synastryAspects(natal.objects, ext.objects),
        objectsExt: [...ext.objects, ErisObjectSynastry],
        housesExt: ext.houses,
      }
    } else {
      const partnerNat = await astro.natal(
        localTime(partner),
        partner.place.lat,
        partner.place.lon,
        false
      );
      return {
        ...partnerNat,
        objects: [...partnerNat.objects, ErisObjectPartner],
        aspects: astro.natalAspects(partnerNat.objects, undefined, partnerNat.houses),
        objectsExt: [],
        housesExt: [],
        name: partner.name
      };
    }
  }

  if (!form.prognostics) throw new Error('Empty prognostics data');

  let { dt, gmt = null, place: { lat, lon } } = form.prognostics as IBaseData;
  dt = getLocalTime(dt, gmt, lat, lon);

  const ext: ICalculation = await (async () => {

    switch (mode) {
      case 'transits': return await astro.natal(dt, lat, lon, false);
      case 'directions': return getDirections(natal, localTime(form.natal), dt);
      case 'solars': return await astro.solars(natal.objects[ObjectType.Sun].lon, dt, lat, lon);
      case 'prog_prog':
      case 'prog_natal': return await astro.progressions(localTime(form.natal), dt, lat, lon);
    }
  })() as ICalculation;

  let forErisDateTime: string = mode === 'transits'
    ? dt
    : mode === 'directions'
      ? localTime(form.natal)
      // @ts-ignore
      : ext.dt;

  let ErisObjectPrognostics = await astro.object(forErisDateTime, 136199 + 10000);

  if (mode === 'directions') {
    // @ts-ignore
    ErisObjectPrognostics = directionShift(ErisObjectPrognostics, ext.shift);
  }

  return {
    ...natal,
    objects: [...natal.objects, ErisObjectNatal],
    aspects: astro.prognosticsAspects(
      mode,
      natal.objects,
      natal.houses,
      ext.objects,
      ext.houses
    ),
    objectsExt: [...ext.objects, ErisObjectPrognostics],
    housesExt: ext.houses
  };
}

const partnerIcons: {
  [key: string]: any,
} = {
  partner1: Partner1Icon,
  partner2: Partner2Icon,
  partner3: Partner3Icon,
  partner4: Partner4Icon,
  partner5: Partner5Icon,
  partner6: Partner6Icon,
  partner7: Partner7Icon,
  partner8: Partner8Icon,
  partner9: Partner9Icon,
};

export const modeMetaData: { [key: string]: {
  title: string;
  showTab: boolean;
  icon?: React.ComponentType;
} } = {
  directions: {
    title: 'astro.directions',
    showTab: false,
    icon: DirectionsIcon,
  },
  horar: {
    title: 'astro.horar',
    showTab: true,
    icon: HorarIcon,
  },
  soul: {
    title: 'astro.formulaSoul',
    showTab: true,
    icon: SoulIcon,
  },
  natal: {
    title: 'astro.natal',
    showTab: true,
    icon: NatalIcon,
  },
  syn_natal: {
    title: 'astro.natal',
    showTab: true,
    icon: NatalIcon,
  },
  transits: {
    title: 'astro.transits',
    showTab: false,
    icon: TransitsIcon,
  },
  solars: {
    title: 'astro.solars',
    showTab: false,
    icon: SolarsIcon,
  },
  prog_natal: {
    title: 'astro.progNatal',
    showTab: false,
    icon: ProgNatalIcon,
  },
  prog_prog: {
    title: 'astro.progProg',
    showTab: false,
    icon: ProgProgIcon,
  }
};
export const isSynNatal = (mode: CircleMode) => /^syn_natal\d*$/.test(mode);

export const isCompatibility = (mode: CircleMode) => /^compatibility\d*$/.test(mode);

export const isRelocation = (mode: CircleMode) => /^relocation\d+$/.test(mode);

export const isPartner = (mode: CircleMode) => /^partner\d+$/.test(mode);

export const compatibilityToPartner = (mode: CircleMode) => mode.replace('compatibility', 'partner') as CircleMode;

export const getPartnerNumber = (mode: CircleMode) => +mode.replace(/\D/g, '') || 0;

export const getModeMetaData = (mode: CircleMode) => {
  if(isPartner(mode)) {
    return {
      title: `astro.${mode}`,
      showTab: true,
      icon: partnerIcons[mode] || PartnerIcon,
    };
  }

  if(isCompatibility(mode)) {
    return {
      title: `astro.${mode}`,
      showTab: true,
      icon: CompatibilityIcon,
    };
  }

  return modeMetaData?.[mode] || {};
}

export const getModeData = (mode: CircleMode, form: IFormData): IBaseData | ISynastryPartnerData | null => {

  if(isPartner(mode) || isCompatibility(mode)) {
    const partnersData = form.partners?.length
      ? form.partners
      : form.synastry
          ? [form.synastry]
          : [];

    const num = getPartnerNumber(mode);
    // if(!num || !partnersData?.[num - 1]) {
    //   return null;
    // }
    return partnersData[num - 1] as ISynastryPartnerData;
  }

  if(mode === 'horar') {
    return form.horar || null;
  }

  if (isPrognostics(mode)) {
    return form.prognostics || null;
  }

  return form?.natal || null;
}

export const getCalculatedMap = async (mode: CircleMode, form: IFormData, mapIndex?: number) => {
	const data = await calculation(mode, form, mapIndex);
	return {
		mode,
		...data
	};
}
