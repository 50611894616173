import React from 'react';

import { radialPoint, signs } from 'src/libs';
import { signsIcons } from 'src/helpers/icons';

import { CirclePartProps, getSectorLength, Group } from './index';
import { hideMapInfoPopup, showMapInfoPopup } from '../../Maps';
import { t } from 'i18next';

interface ZodiacsProps extends CirclePartProps {
  horarSigns: number[];
  direction: 'ccw' | 'cw';
}

export default class Zodiacs extends React.Component<ZodiacsProps> {
  private readonly _zodiacs = React.createRef<SVGSVGElement>();
  private readonly _icons: React.RefObject<SVGGElement>[] = [];
  private readonly _coloredSectors: React.RefObject<SVGPathElement>[] = [];
  private readonly _hoverArea: React.RefObject<SVGCircleElement>[] = [];
  private readonly _coloredCurves: React.RefObject<SVGPathElement>[] = [];

  constructor(props: ZodiacsProps) {
    super(props);

    for (let i = 0; i < 12; i++) {
      this._icons.push(React.createRef<SVGGElement>());
      this._coloredSectors.push(React.createRef<SVGPathElement>());
      this._hoverArea.push(React.createRef<SVGCircleElement>());
      this._coloredCurves.push(React.createRef<SVGPathElement>());
    }
  }

  shouldComponentUpdate(newProps: ZodiacsProps) {
    this.update(newProps);
    return !!newProps.isEditor || newProps.mode !== this.props.mode;
  }

  componentDidMount() {
    this.update(this.props);
  }

  update = (newProps: ZodiacsProps): void => {
    requestAnimationFrame(() => {
      if(!this._zodiacs.current) return false; // TODO: WTF
      const { rotation, personalization, horarSigns } = newProps;
      const { zodiacs } = personalization.circle;
      const { computedRadius } = newProps;

      const coloredSectorOpacity = () => {
        if (zodiacs.style === 0) { return 0 }
        if (zodiacs.style === 1) { return 0.3 }
        if (zodiacs.style === 2) { return 0.3 }
        if (zodiacs.style === 3) { return 1 }
        return 1;
      };

      const externalRadius = newProps.radius * (newProps.hasExt ? computedRadius.zodiacsExternalExt : computedRadius.zodiacsExternal);
      const innerRadius = newProps.radius * (newProps.hasExt ? computedRadius.zodiacsInternalExt : computedRadius.zodiacsInternal);

      const iconsRadius = (externalRadius + innerRadius) / 2;
      const iconSize = newProps.radius * zodiacs.iconSize;

      const sector = (r: number, a: number, length: number) => {
      if (newProps.mode === 'horar' || newProps.mode === 'soul') {
        return {
          start: radialPoint(newProps.x, newProps.y, r, a + zodiacs.gap / 2),
          end: radialPoint(newProps.x, newProps.y, r, a + length - zodiacs.gap)
        }
      } else {
        const startA = newProps.direction === 'cw' ? -(a - zodiacs.gap / 2) : a + zodiacs.gap / 2;
        const endA = newProps.direction === 'cw' ? -(a - length - zodiacs.gap) : a + length - zodiacs.gap;
        return {
        start: radialPoint(newProps.x, newProps.y, r, startA),
        end: radialPoint(newProps.x, newProps.y, r, endA)
      }}
      }

      for (let i = 0; i < 12; i++) {
        const dirCoefficient = (newProps.mode === 'horar' || newProps.mode === 'soul') ? 0 : (newProps.direction === 'cw' ? 30 : 0);
        const a = horarSigns.length ? 180 + horarSigns[i] : rotation + i * 30 + dirCoefficient;
        const sectorLength = horarSigns.length ? getSectorLength(i, horarSigns) : 30;

        const internal = sector(innerRadius, a, sectorLength);
        const external = sector(externalRadius, a, sectorLength);

        const g = this._zodiacs.current!.childNodes[i] as SVGSVGElement;

        const opacity = !newProps.highlights || newProps.highlights.includes(i) ? 1 : 0.2;

        g.setAttribute('fill', `rgba(var(--circle-zodiacs-${signs[i].element}-rgb-${personalization.circle.colorThemes.zodiacs.active}), ${opacity})`);
        // g.setAttribute('stroke', `rgba(${this.personalization.circle.colorThemes.zodiacs[signs[i].element]}, ${opacity})`);

        const icon = g.lastChild as SVGSVGElement;
        const iconA = ((newProps.mode === 'horar' || newProps.mode === 'soul') ? a + sectorLength / 2 : newProps.direction === 'cw' ? -(a - sectorLength / 2) : a + sectorLength / 2)
        const iconPos = radialPoint(newProps.x, newProps.y, iconsRadius, iconA);

        icon.setAttribute('x', (iconPos.x - iconSize / 2).toString());
        icon.setAttribute('y', (iconPos.y - iconSize / 2).toString());
        icon.setAttribute(
          'fill',
          personalization.circle.zodiacs.style === 3
            ? `rgba(0, 0, 0, ${opacity})`
            : `rgba(var(--circle-zodiacs-${signs[i].element}-rgb-${personalization.circle.colorThemes.zodiacs.active}), ${opacity})`
        );

        const hover = this._hoverArea[i].current as SVGGElement;

        hover.setAttribute('cx', iconPos.x.toString());
        hover.setAttribute('cy', iconPos.y.toString());

        const coloredSector = this._coloredSectors[i].current as SVGGElement;

        coloredSector.setAttribute('fill', `rgba(var(--circle-zodiacs-${signs[i].element}-rgb-${personalization.circle.colorThemes.zodiacs.active}), ${coloredSectorOpacity() * opacity})`);
        coloredSector.setAttribute('d', `
          M ${internal.start.x} ${internal.start.y}
          A ${innerRadius} ${innerRadius} 0 0 0 ${internal.end.x} ${internal.end.y}
          L ${external.end.x} ${external.end.y}
          A ${externalRadius} ${externalRadius} 0 0 1 ${external.start.x} ${external.start.y}
        `);

        const coloredCurve = this._coloredCurves[i].current as SVGGElement;

        coloredCurve.setAttribute('display', personalization.circle.zodiacs.style === 0 || personalization.circle.zodiacs.style === 1 ? 'block' : 'none');
        coloredCurve.setAttribute('stroke', `rgba(var(--circle-zodiacs-${signs[i].element}-rgb-${personalization.circle.colorThemes.zodiacs.active}), ${opacity})`);
        coloredCurve.setAttribute('d', `
          M ${internal.start.x} ${internal.start.y}
          A ${innerRadius} ${innerRadius} 0 0 0 ${internal.end.x} ${internal.end.y}
        `);
      }
    });
  };

  render() {
    const { props } = this;
    const { zodiacs } = props.personalization.circle;

    const iconSize = props.radius * zodiacs.iconSize;
    const elements: any[] = [];


    for (let i = 0; i < 12; i++) {
      const Icon = signsIcons[i];


      elements.push(
        <Group
          key={`zodiac_${this.props.mode}_${i}`}
          onMouseEnter={(ev: any) => {
            showMapInfoPopup(ev.target, [`${t(signs[i].ru)}`]);
          }}
          onMouseLeave={_ => {
            hideMapInfoPopup();
          }}
          onClick={() => this.props.onHelp('signs', i)}
        >
          {/* Colored sector visible in light theme only */}
          <path
            ref={this._coloredSectors[i]}
          />

          {/* Colored curve */}
          <path
            ref={this._coloredCurves[i]}
            strokeWidth={2}
            fill="none"
          />

          {/* Hover area */}
          <circle
            ref={this._hoverArea[i]}
            r={iconSize}
            fill="transparent"
            stroke="transparent"
          />

          <Icon
            stroke="none"
            fill="inherit"
            width={iconSize}
            height={iconSize}
            overflow="visible"
          />
        </Group>
      );
    }

    return (
      <Group ref={this._zodiacs}>
        {elements}
      </Group>
    );
  }
}
