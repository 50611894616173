import React from 'react';
import styled, { css, keyframes } from 'styled-components';
import _ from 'lodash';

import { Loader, ICustomizationData, IMap, IPoint } from 'src/libs';

import Circle, { IHighlightsData } from './components/Circle';
import { IStrongs } from './components/Widgets/data';
import { IHighlight } from '.';
import { hasExt, highlightAspect } from './components/Widgets/AspectsTable';

import Theme from 'src/theme';
import Star3SvgWhite from 'src/assets/illustrations/star3-white.svg';
import Star3SvgDark from 'src/assets/illustrations/star3-dark.svg';
import Star2SvgWhite from 'src/assets/illustrations/star2-white.svg';
import Star2SvgDark from 'src/assets/illustrations/star2-dark.svg';
import StarSvgWhite from 'src/assets/illustrations/star-white.svg';
import StarSvgDark from 'src/assets/illustrations/star-dark.svg';

export function showMapInfoPopup(el: Element, lines: string[]) {
  if (!el) return;
  const objR = el.getBoundingClientRect();

  const info = document.querySelector('#map-info-popup') as HTMLDivElement;

  if (!info) return;

  const fragment = document.createDocumentFragment();

  info.style.display = 'flex';

  for (let i = 0; i < lines.length; i++) {
    const line = document.createElement('div');
    line.innerHTML = lines[i];
    fragment.append(line);
  }

  info.innerHTML = '';
  info.appendChild(fragment);

  // const infoR = info.getBoundingClientRect();

  const width = document.documentElement.clientWidth;
  const ml = (width - info.offsetWidth) / 2 + 20;
  info.style.marginLeft = ml + 'px';
  info.style.zIndex = '3'
}

export function hideMapInfoPopup() {
  const info = document.querySelector('#map-info-popup') as HTMLDivElement;

  if (!info) { return }

  info.style.display = 'none';
}

export default class Maps extends React.PureComponent<{
  maps: IMap[];
  strongs: IStrongs;
  natal: IMap | null;
  highlights: IHighlightsData;
  pinnedAspects: number[];
  currentMode: string;
  aspectsTableMode: string;
  onChanged(key: string, value: any): void;
  personalization: ICustomizationData
}, {
  scale: number;
  screenWidth: number;
}> {
  private readonly _container = React.createRef<HTMLDivElement>();
  private readonly _scroll = React.createRef<HTMLDivElement>();

  private _scrolling = false;
  private _moving = false;
  private _spacePressed = false;
  private _zoomPoint: IPoint | null = null;
  private readonly personalization: ICustomizationData = this.props.personalization;
  private readonly computedRadius: any = this.props.personalization.computedRadius;

  private _keyScroll = false;
  private _timeoutId: number | null = null;

  private _prevScroll = {
    left: 0,
    top: 0,
    width: 0,
    height: 0
  };

  state = {
    scale: 1,
    screenWidth: window.outerWidth
  };

  componentDidUpdate() {
    const cont = this._container.current as HTMLDivElement;

    if (cont && this._zoomPoint) {
      const { left, top, width, height } = this._prevScroll;
      const { x, y } = this._zoomPoint;

      const w2 = cont.clientWidth / 2;
      const h2 = cont.clientHeight / 2;

      cont.scrollTo(
        x + (left + w2) * cont.scrollWidth / width - w2,
        y + (top + h2) * cont.scrollHeight / height - h2
      );

      this._zoomPoint = null;
    }
  }

  changeHandler = (key: string, value: any) => {
    //if (key === 'highlights' && this._keyScroll) { return }
    //this.props.onChanged(key, value);
  };

  setScreenWidth = () => {
      this.setState({
        ...this.state,
        screenWidth: window.outerWidth
      })
  }

  componentDidMount() {
    window.addEventListener('resize', this.setScreenWidth)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.setScreenWidth)
  }

  render() {

    const { maps, strongs, natal } = this.props;

    const gap = this.state.screenWidth / 8;
    const radius = this.state.screenWidth / 2 - gap;

    const diameter = radius * 2;

    const mapsWidth = maps.length * diameter + gap * maps.length * 2;

    const horar = maps.find(m => m.mode === 'horar');

    return (
      <>
        <Container
          ref={this._container}
          isHorar={!!horar}
        >
          {maps.length ?
            <div id="print-map">
              <MapSvg
                viewBox={`0 0 ${mapsWidth} ${diameter}`}
                shapeRendering="geometricPrecision"
                overflow="visible"
                style={{
                  width: `${mapsWidth * this.state.scale / 16}rem`,
                  height: `${diameter * this.state.scale / 16}rem`
                }}
              >
                <defs>
                  <pattern id="circle-star1" x="0" y="0" width="100%" height="100%" patternTransform="translate(-2, -2)">
                    <image href={Theme.current === 'dark' ? StarSvgWhite : StarSvgDark} x="8" y="8" width="8" height="8"></image>
                  </pattern>
                  <pattern id="circle-star2" x="0" y="0" width="100%" height="100%" patternTransform="translate(-2, -2)">
                    <image href={Theme.current === 'dark' ? Star2SvgWhite : Star2SvgDark} x="8" y="8" width="8" height="8"></image>
                  </pattern>
                  <pattern id="circle-star3" x="0" y="0" width="100%" height="100%" patternTransform="translate(-2, -2)">
                    <image href={Theme.current === 'dark' ? Star3SvgWhite : Star3SvgDark} x="8" y="8" width="8" height="8"></image>
                  </pattern>
                </defs>
                
                {maps.map((m, i) => {
                  const pinnedAspects: IHighlight[] = [];

                  if (m.mode === this.props.aspectsTableMode) {
                    this.props.pinnedAspects.map(id =>
                      highlightAspect(m.mode, m.aspects[id], hasExt(m.mode)).forEach(h => pinnedAspects.push(h))
                    );
                  }

                  return (
                    <Circle
                      key={`map_${i}`}
                      mode={m.mode}
                      x={(1 + 2 * i)*(radius + gap)}
                      y={radius}
                      r={radius}
                      houses={m.houses}
                      fixedStars={m.fixedStars || []}
                      objects={m.objects}
                      housesExt={m.housesExt || []}
                      objectsExt={m.objectsExt || []}
                      soulObjects={
                        // FIXME:
                        // @ts-ignore
                        m.soulObjects || []
                      }
                      chains={m.chains || []}
                      aspects={m.aspects}
                      strongs={strongs}
                      highlights={{
                        items: [
                          ...(this.props.highlights?.items?.filter(h => h.map === m.mode) || []),
                          ...pinnedAspects
                        ],
                        aspects: this.props.highlights.aspects
                      }}
                      //@ts-ignore
                      showFixedStars={maps[0].showFixedStars}
                      personalization={this.personalization as ICustomizationData} // FIXME:
                      computedRadius={this.computedRadius}
                      onChanged={(key, value) => this.changeHandler(key, value)}
                      soulStrongs={m.mode === 'soul' && m.strongs ? m.strongs : []}
                    />
                  );
                })}
              </MapSvg>
              {/*horar && <Horar objects={horar.objects} personalization={this.personalization} /> // FIXME: */}
            </div> : <Loader/>
          }

					<MapInfoPopup id="map-info-popup"/>
        </Container>
      </>
    );
  }
}

const Container = styled.div<{ isHorar: boolean }>`
  height: 100%;
  width: min-content;

  flex: 1;

  display: flex;
  align-items: center;
  padding: 3rem 0;
  box-sizing: border-box;

  & > div:not(:last-child) {
    margin: auto;
    display: flex;
    align-items: center;

    ${props => props.isHorar && css`
      align-items: baseline;
    `}

  }

  & > span {
    display: none;
    position: absolute;

    bottom: 5.5em;
    background-color: red;

    width: 3em;
    height: 0.5em;
    border-radius: 0.25em;
    opacity: 0.5;

    :hover {
      opacity: 0.8;
    }
  }

  scrollbar-width: none;

  ::-webkit-scrollbar {
    display: none;
  }
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

const MapInfoPopup = styled.div`
  position: fixed;
  flex-direction: column;
  align-items: flex-start;
  color: var(--text-primary);
  padding: 0.5em;
  border-radius: 0.25em;
  border: 1px solid var(--text-third);
  background-color: var(--background);
  font-size: 0.875rem;
  pointer-events: none;

  animation: ${fadeIn} 0.25s ease-in-out;
  white-space: nowrap;

  width: fit-content;

	display: none;

  & > div + div {
    margin-top: 0.2rem;
  }
`;

const MapSvg = styled.svg`

  @media print {
    display: flex;
    position: relative;
    justify-content: center;
    width: 100vw !important;
    height: 100vw !important;
    left: -2rem;
    margin: 0 !important;
    margin-top: 5rem;
    margin-bottom: 5rem;
    overflow: visible !important;
  }
`;
