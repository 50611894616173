import { combineReducers } from 'redux';

import profile from './profile/profile';
import notifications from './notifications/notifications';
import forms from './forms/forms';
import settings from './settings'
import payments from './payments'
import folders from './folders'
import labels from './labels'
import courses from "./courses";

export const rootReducer = combineReducers({
  profile,
  notifications,
  forms,
  settings,
  payments,
  folders,
  labels,
  courses,
})

export type IRootState = ReturnType<typeof rootReducer>

