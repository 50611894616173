import React, { useEffect, useMemo, useState } from 'react';
import styled, { css } from 'styled-components';

import { RefreshIcon } from 'src/assets/icons/system/index';
import { OkIcon } from 'src/assets/icons/system/index';
import { ArrowLeftIcon } from 'src/assets/icons/arrows/index';
import { TopBottomTrianglesIcon } from 'src/assets/icons/arrows/index';
import { TMode } from '../..';
//import { modes } from '../ModeSelector';
import { IBaseData, IFormData, toDateTime, CircleMode, fillNumber, IMap, fromDateTime } from 'src/libs';
import Timeline, { daysInMonth } from './Timeline';
import { checkGenChanged } from 'src/helpers/astro';
import { useTranslation } from 'src/i18n/useTranslation';
import {useParams} from "react-router-dom";
import { getModeData, getModeMetaData, getPartnerNumber, isCompatibility, isPartner } from '../../utils';

export type TTimeType = 'day' | 'month' | 'year' | 'hour' | 'minutes' | 'seconds';

export interface IParts {
  day: number,
  month: number,
  year: number,
  hour: number,
  minutes: number,
  seconds: number,
}

export default function EditDateAndTime({
  mode,
  current,
  form,
  updateForm,
  setOpenConfirmPopup,
  calcMap
}:{
  mode: TMode,
  current: CircleMode,
  form: IFormData,
  updateForm(v: IFormData): void,
  setOpenConfirmPopup(v: Function): void;
  calcMap(v: IFormData): void;
}) {
  const { t } = useTranslation();
  //const natalMode = mode === 'prognostics' || mode === 'synastry';
  const [editCell, setEditCell] = useState<TTimeType|null>(null);
  const [currentMode, setCurrentMode] = useState(current);
  const multipleTimeControls = useMemo(() => isCompatibility(current), [current]);
  
  const data: IBaseData = getModeData(currentMode, form) || getModeData('natal', form)!;

  const dateTime = toDateTime(data.dt);
  const dateParts = dateTime.date.split('.');
  const timeParts = dateTime.time.split(':');
  
  const params = useParams();
  const formId = params.id && +params.id
  const isTempMap = formId === -1
  
  const initialValue = {
    day: +dateParts[0],
    month: +dateParts[1],
    year: +dateParts[2],
    hour: +timeParts[0],
    minutes: +timeParts[1],
    seconds: +timeParts[2],
  }
  const [parts, setParts] = useState<IParts>(initialValue);
  
  const { icon: Icon, title } = useMemo(() => getModeMetaData(currentMode), [currentMode]);

  useEffect(() => {
    setParts(initialValue);
  }, [form, currentMode]);

  useEffect(() => {
    setCurrentMode(current as CircleMode);
  }, [current, mode]);

  const refreshDateTime = () => {
    const date = new Date();
    const nowParts = {
      day: date.getDate(),
      month: date.getMonth() + 1,
      year: date.getFullYear(),
      hour: date.getHours(),
      minutes: date.getMinutes(),
      seconds: date.getSeconds(),
    }
    setTimeout(() => handleSave(null, nowParts), 0);
  }

  const handleCellClick = (type: TTimeType) => {
    document.getElementById('timelinePopup')!.style.background = 'none';
    document.getElementById('timelinePopup')!.style.overflow = 'visible';
    document.getElementById('timelinePopup__pullline')!.style.display = 'none';
    document.getElementById('footer__buttons')!.style.visibility = 'hidden';
    setEditCell(type);
  }

  const resetDOM = () => {
    document.getElementById('timelinePopup')!.style.background = 'var(--bg-popup)';
    document.getElementById('timelinePopup')!.style.overflow = 'auto';
    document.getElementById('timelinePopup__pullline')!.style.display = 'block';
    document.getElementById('footer__buttons')!.style.visibility = 'initial';
    setEditCell(null);
  }

  const setDateTimeToForm = (dateTime: string) => {
    const currentModeBlock: IBaseData = getModeData(currentMode, form)!;
    const partnersData = form.partners?.length
      ? form.partners
      : form.synastry
          ? [form.synastry]
          : [];

    const num = isCompatibility(currentMode) || isPartner(currentMode)
      ? getPartnerNumber(currentMode)
      : 0;

    const newForm = mode === 'synastry' && num
      ? {
          ...form,
          partners: [
            ...partnersData.slice(0, Math.max(0, num - 2)),
            {
              ...currentModeBlock,
              dt: dateTime,
            },
            ...partnersData.slice(num),
          ],
        }
      : {
          ...form,
          [mode === 'synastry' ? 'natal' : mode]: {
            ...currentModeBlock,
            dt: dateTime,
          },
        };

    return newForm;
  }


  const handleSave = (_event: React.MouseEvent | null, newValue?: IParts) => {
    const dateTime = getDateTimeFromParts(newValue || parts);

    const newForm = setDateTimeToForm(dateTime);

    const gen = {
      autoprediction: form.gen.autoprediction,
      devplan: form.gen.devplan,
      rectification: form.gen.rectification,
    }

    if(!isTempMap && checkGenChanged(gen, form.natal.dt, newForm.natal.dt, form.natal.place, form.natal.place)) {
      setOpenConfirmPopup(() => () => {
        newValue && setParts(newValue);
        const gen = {
          autoprediction: false,
          devplan: false,
          rectification: false,
        }
        updateForm({...newForm!, gen});
        resetDOM();
      })
    } else {
      updateForm(newForm);
      resetDOM();
    }
  }

  const handleCancel = () => {
    resetDOM();
    setParts(initialValue);
    calcMap(form);
  }

  const getNewParts = (type: TTimeType, value: number) => {
    let newParts = {...parts, [type]: fillNumber(value)};
    
    if(type === 'year' || type === 'month') {
      const maxDays = daysInMonth(+parts.year, +value);
      if(+parts.day > maxDays) {
        newParts = {...parts, [type]: fillNumber(value), 'day': maxDays};
      }
    }

    return newParts;
  }

  const getDateTimeFromParts = (parts: IParts) => {
    const date = `${fillNumber(+parts.day)}.${fillNumber(+parts.month)}.${+parts.year}`;
    const time = `${fillNumber(+parts.hour)}:${fillNumber(+parts.minutes)}:${fillNumber(+parts.seconds)}`;
    return fromDateTime(date, time);
  }
  
  const onChange = (type: TTimeType, value: number) => {
    setParts(getNewParts(type, value));
  }

  const onFixed = (type: TTimeType, value: number) => {
    const dateTime = getDateTimeFromParts(getNewParts(type, value));
    const newForm = setDateTimeToForm(dateTime);
    calcMap(newForm);
  }
  
  const changeMode = () => {
    if(isCompatibility(current as CircleMode)) {
      setCurrentMode(currentMode === 'natal' ? current : 'natal' as CircleMode);
    }
  }

  return <>
    <Header isEdit={!!editCell}>
      <div></div>
      <HeaderTitle>
        <Icon style={{height: '1.25rem'}} />
        <span>{t(title)}</span>
        {multipleTimeControls && <TopBottomTrianglesIcon onClick={changeMode} />}
      </HeaderTitle>
      <div onClick={refreshDateTime}><RefreshIcon /></div>
    </Header>
    {editCell && <div><Timeline type={editCell} parts={parts} onChange={onChange} onFixed={onFixed} /></div>}
    <DateAndTime>
      <DateBlock>
        <DateAndItemCell onClick={() => handleCellClick('day')} active={editCell === 'day'}>{fillNumber(parts['day'])}</DateAndItemCell>
        <DateAndItemCell onClick={() => handleCellClick('month')} active={editCell === 'month'}>{fillNumber(parts['month'])}</DateAndItemCell>
        <DateAndItemCell onClick={() => handleCellClick('year')} active={editCell === 'year'}>{parts['year']}</DateAndItemCell>
      </DateBlock>
      <TimeBlock>
        <DateAndItemCell onClick={() => handleCellClick('hour')} active={editCell === 'hour'}>{fillNumber(parts['hour'])}</DateAndItemCell>
        <DateAndItemCell onClick={() => handleCellClick('minutes')} active={editCell === 'minutes'}>{fillNumber(parts['minutes'])}</DateAndItemCell>
        <DateAndItemCell onClick={() => handleCellClick('seconds')} active={editCell === 'seconds'}>{fillNumber(parts['seconds'])}</DateAndItemCell>
      </TimeBlock>
    </DateAndTime>
    <Footer isEdit={!!editCell}>
      <div onClick={handleCancel}><ArrowLeftIcon /></div>
      <div onClick={handleSave}><OkIcon /></div>
    </Footer>
  </>
}

const Header = styled.div<{isEdit: boolean}>`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.875rem;

  svg {
    color: var(--text-secondary);
  }

  ${p => p.isEdit && css`
    display: none;
  `}
`;

const Footer = styled.div<{isEdit: boolean}>`
  position: fixed;
  left: 0;
  bottom: -3.5rem;

  width: 100%;
  display: flex;
  justify-content:space-between;
  padding: 1rem;
  color: var(--text-secondary);

  ${p => !p.isEdit && css`
    display: none;
  `}
`;
 
const HeaderTitle = styled.div`
  display: flex;
  align-items: center;
  font-size: 1.125rem;
  > svg:first-child {
    margin-right: 0.625rem;
  }
  span {
    margin-right: 0.375rem;
  }
`;

const DateAndTime = styled.div`
  display: grid;
  grid-template-columns: 1.15fr 1fr;
  grid-gap: 1rem;
`;

const DateBlock = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1.4fr;
  grid-gap: 0.375rem;
`;

const TimeBlock = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 0.375rem;
`;

const DateAndItemCell = styled.div<{active: boolean}>`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.125rem;
  height: 3rem;

  background: var(--input-background);
  border: 1px solid ${p => p.active ? 'var(--color-blue)' : 'var(--input-border)'};
  border-radius: 0.375rem;
`;
