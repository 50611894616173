import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import Objects from './Objects';
import { useTranslation } from 'src/i18n/useTranslation';
import { IWidgetData } from '../data';
import { isCompatibility, isPartner } from 'src/utils';

export default function Coordinates(props: { data: IWidgetData }) {
  const { t } = useTranslation();
  const [selected, setSelected] = useState<string>('partner1');

  const compatibilityItems = props.data.maps.filter(map => isCompatibility(map.mode)).map(map => ({
    id: map.mode,
    planets: map.objects,
    houses: map.houses,
  }))

  const mode = props.data.aspectsTableMode ?? [];
  const prognosticsMode = [
    'transits',
    'solars',
    'directions',
    'prog_prog',
    'prog_natal',
  ];

  if (mode === 'natal' || mode === 'syn_natal') {
    return (
      <Container>
        <Objects
          planets={props.data.maps[0].objects}
          houses={props.data.maps[0].houses}
        />
      </Container>
    );
  }

  if (isCompatibility(mode)) { 
    const currentMap = props.data.maps.find(map => mode === map.mode);   
    return (
      <Container>
        {/* <HeaderWrapper>
          {compatibilityItems.map(comItem => {
            return (
              <Item
                key={`comp_item_${comItem.id}`}
                onClick={() => setSelected(comItem.id)}
                isSelected={selected === comItem.id}
              >
                {t(`astro.${comItem.id}`)}
              </Item>
            )
          })}
        </HeaderWrapper> */}
        {currentMap?.objectsExt! && currentMap?.housesExt! ?
          <Objects
          mode={mode}
          planets={currentMap?.objectsExt!}
          houses={currentMap?.housesExt!}
          /> 
          : null
        }
      </Container>
    );
  }

  if (isPartner(mode)) {
    const currentMap = props.data.maps.find(map => mode === map.mode);
    return (
      <Container>
        {currentMap?.objects! && currentMap?.houses! ?
        <Objects
        mode={mode}
        planets={currentMap?.objects!}
        houses={currentMap?.houses!}
        />
        : null
      }
      </Container>
    );
  }

  if (prognosticsMode.includes(mode)) {
    const currentMap = props.data.maps.find(map => mode === map.mode);
    return (
      <Container>
        {currentMap?.objectsExt! && currentMap?.housesExt! ?
          <Objects
          mode={mode}
          planets={currentMap?.objectsExt!}
          houses={currentMap?.housesExt!}
          /> 
          : null
        }
      </Container>
    );
  }
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  margin-bottom: 1rem;
  /* border-bottom: 1px solid var(--input-border); */

`;

const Item = styled.div<{ isSelected: boolean }>`
      width: 6rem;
    text-align: center;

    ${(props) =>
    props.isSelected &&
    css`
      border-bottom: 2px solid var(--accent-blue);
    `}
`;
